import React, {
	createContext,
	useContext,
	useState,
	useRef,
	useEffect,
} from "react";

// Contextの作成
const BGMContext = createContext<{
	bgm: boolean;
	toggleBgm: () => void;
	audioRef: React.RefObject<HTMLAudioElement>;
}>({
	bgm: false,
	toggleBgm: () => {},
	audioRef: { current: null },
});

// Providerの作成
export const BGMProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [bgm, setBgm] = useState(false); // 初期状態をfalseに設定（OFF）
	const audioRef = useRef<HTMLAudioElement | null>(null);

	const toggleBgm = () => {
		setBgm((prev) => !prev);
	};

	useEffect(() => {
		// BGMの再生/停止
		if (audioRef.current) {
			if (bgm) {
				audioRef.current.play();
			} else {
				audioRef.current.pause();
			}
		}
	}, [bgm]);

	return (
		<BGMContext.Provider value={{ bgm, toggleBgm, audioRef }}>
			<audio ref={audioRef} src="sound/bg-sound.mp3" loop></audio>
			{children}
		</BGMContext.Provider>
	);
};

// BGMのカスタムフック
export const useBGM = () => useContext(BGMContext);
