interface GlossaryItem {
    title: string;
    subtitle?: string;
    description: string;
    image?: string;
}
  
const glossaries: GlossaryItem[] = [
    {
        title: "星屑の落涙",
        description: "北半球に位置する四大陸などへ降り注いだ無数の隕石群と、これにより巻き起こった大事変の総称。この事変により各国の主要都市は壊滅的な被害を受け、五十億人強の死傷者が出る惨事となった。",
        image: ""
    },
    {
        title: "扁桃核内細胞寄生因子（パラシトス）",
        description: "星屑の落涙により齎された、太陽系外より飛来した未知の生命体。地表に降り注いだ無数の隕石の内部に生息していたと思われる外宇宙由来の生物で、細菌の一種と捉えられている。あらゆる極限環境においても生存可能である特性を備えており、人間の扁桃体に限定して寄生するという習性を持つ。扁桃体、言い換えては人間の「感情」に寄生することで、宿主の強い情動・感情と感応し突然変異にも似たあらゆる変化を宿主に与える。",
        image: ""
    },
    {
        title: "第一中央特区 武蔵",
        description: "新都とも呼ばれる。旧時代において東京と呼ばれた街であり、星屑の落涙によって壊滅的な損壊を受けたが、生き残った人々の手によって復興を果たし、現在は野放しとなっている末期症状発露体（エゴ・ファージ）や暴徒たちに対し完璧な防御策を持つ唯一の都市部として機能している。ただし、現在このエリアに居住可能なのは富裕層や政府要人を中心とした一部の限られた人々に限られる。",
        image: ""
    },
    {
        title: "アスクレピオスの杖",
        subtitle: "新都衛生管理保全機構",
        description: "星屑の落涙以降、パラシトスによる寄生感染被害への対策のために急遽設立された医療提供並びに市民の健康保全を目的とした組織。事実上、新都中央省庁における司法の最高機関であり、「アスクレピオスの杖」という通称を持つ特務執行第六課はパラシトスの脅威に対し新都が抱える唯一無二の切り札にして最高戦力として機能している。また、第六課の構成員は「執行士」と呼ばれ、他課の職員とは明確な区別がなされている。",
        image: ""
    },
    {
        title: "過たず招かれた災筐",
        subtitle: "（パンドーラ）",
        description: "空臥明止、及び旧都民を中心に創設された反体制派テロ組織。衛生管理保全機構により第一中央特区 武蔵から排斥されたキャリアー・非キャリアーたちによって構成されている。彼らの行動原理は貧困支援拡充の要求・新都の整備推進および旧都民への解放……など様々だが、それらはすべて新都奪還と保全機構の転覆に帰結する。",
        image: ""
    },
    {
        title: "保菌者",
        subtitle: "（キャリアー）／シネスティマ",
        description: "星屑の落涙以降に見られるようになった、パラシトスに寄生された者たちの総称。パラシトスに寄生されている以外は平時において身体・精神的にも異常はないのだが、感情を激しく高ぶらせることで肉体の一部が異形化し同時にシネスティマと呼ばれる固有の異能力を発現させることができる。常人にはない強力な能力を悪用する暴徒も数多く存在し、加えていつ暴走して末期症状発露体（エゴ・ファージ）に変貌するかも定かではないため、キャリアーへの世間の目は厳しく、基本的に忌み嫌われる傾向にある。",
        image: ""
    },
    {
        title: "真位原型（タイプ・ホロ）",
        description: "彼らに寄生したパラシトスは「原始種（ルビ：オリジン）」と呼称され、明確な自己意識を持ち、宿主と精神的な対話をすら行う。星屑の落涙以後、最も初期の段階でパラシトスにより寄生された人間に見られる。他のパラシトスと異なり原始種は宿主との精神感応の値が極めて高く、キャリアーが発動するシネスティマも規模・出力などにおいて非常に強力な形として発現する。だが前述の通り原始種は明確に自己意識を持つため、多くの個体は宿主の肉体・精神を支配下へ置くための激しい侵食を行い、末期症状発露体（エゴ・ファージ）へ変成しやすいとされている。",
        image: ""
    },
    {
        title: "新位元型（タイプ・ネオ）",
        description: "真位原型（タイプ・ホロ）と同等か、それ以下に発見数は少ないが、近年になり見られるようになった保菌種。これまでのタイプにおけるパラシトスが大なり小なり宿主の乗っ取りを活動原理としていたことに対し、このタイプに分類されるパラシトスは寄生しておきながら、宿主の意思・行動を容認するなど対等な共生関係を築こうとしている。人と共に生きることを望んだパラシトス。それらはきっと、団結するヒトの意思に魅せられた。",
        image: ""
    },
    {
        title: "分位類型（タイプ・レクト）",
        description: "星屑の落涙から数年が経過し、パラシトスの存在が普遍的なものとなり、その特性の一部を解明した頃に見られるようになった種型。俗に「量産型」とも呼ばれるもので、現在世界中に存在するキャリアーのほとんどはこのタイプに該当する。寄生するパラシトスは新位元型（タイプ・ネオ）ほどではないが自己意識を持つものの、宿主に対する干渉力は弱い。発現する能力も新位元型に比べれば弱いものの、超人的能力を獲得していることは言うまでもない。また末期症状へも比較的進行しにくいが、あくまでも猶予が幾ばくか与えられるというだけで、遠くない内に異形化することは確定している。",
        image: ""
    },
    {
        title: "フトゥルミウム",
        description: "星屑の落涙で降り注いだ隕石群の残骸から発見された、これまで地球上に存在しなかった新物質。ラテン語の「futurum（未来）」から名付けられたこの物質は、依然その物理的／化学的特徴については研究段階であるが、保菌者とパラシトスが発生させる超常能力との共鳴を引き起こすとされている。現在執行士たちの用いる武器として、極秘裏かつ試験的にフトゥルミウム性の装備導入が進められている。",
        image: ""
    },
    {
        title: "末期症状発露体",
        subtitle: "エゴ・ファージ",
        description: "星屑の落涙以降、世界各地で見られるようになった異形の怪物。その正体は、パラシトスによる寄生侵食が末期段階まで進行した末に、シネスティマが暴走したことでヒトとしての原型を保てなくなった保菌者（キャリアー）の成れの果て……つまり元人間である。保菌者（キャリアー）の種別を問わずシネスティマが暴走した状態。かつて人間であった頃に強く抱いていた感情の一念のみが過剰に膨大しており、それ以外の感情を喪失し本能のままに動き続け、その個体の多くが人間に危害を加える恐れがある。",
        image: ""
    },
    {
        title: "新都大衙門",
        description: "第六課を含めた衛生管理保全機構の各課代表、および新都中央省庁各大臣による特別議会。パラシトス及び保菌者（キャリアー）等についての方針の最終決定がその主たる役割であり、立法・行政における最高機関である。",
        image: ""
    }
];
  
export default glossaries;
  