export interface Product {
  title: string;
  price: number;
  url: string;
  image?: string;
  stocks: number;
}

export const products: Product[] = [
  {
    title: "パラシトスシンドローム -Stage1 寄生因子-",
    price: 1000,
    url: "https://nubiaofficial.stores.jp/items/60b88e161c699265f93731b7",
    image: "img/product/stage-01-cd.jpg",
    stocks: 1,
  },
  {
    title: "パラシトスシンドローム Stage 2～別つ涙～",
    price: 1500,
    url: "https://nubiaofficial.stores.jp/items/60b88f6f1c699265ab373274",
    image: "img/product/stage-02-cd.jpg",
    stocks: 1,
  },
];
