import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { SITE_NAME } from "../constants/siteConfig";
import Hero from "../features/home/Hero";
import Story from "../features/home/Story";
import Characters from "../features/home/Characters";
import Movie from "../features/home/Movie";
import Credit from "../features/home/Credit";
import LoadingScreen from "../features/home/LoadingScreen";

const Home: React.FC = () => {
	const [loadingComplete, setLoadingComplete] = useState(false);
	const { hash } = useLocation();

	// フラグメント識別子やページ内移動時にはロード画面を表示しない
	useEffect(() => {
		if (hash) {
			// フラグメント識別子がある場合はロード画面を表示せず即座にコンテンツを表示
			setLoadingComplete(true);
		}
	}, [hash]);

	const handleLoadingComplete = () => {
		setLoadingComplete(true);
	};

	return (
		<div>
			<Helmet>
				<title>{SITE_NAME}</title>
			</Helmet>
			{/* フラグメント識別子がない場合はロード画面を表示 */}
			{!loadingComplete && !hash ? (
				<LoadingScreen onLoadingComplete={handleLoadingComplete} />
			) : (
				<>
					<Hero />
					<Story />
					<Characters />
					<Movie />
					<Credit />
				</>
			)}
		</div>
	);
};

export default Home;
