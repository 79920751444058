import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Menu: React.FC = () => {
	const [isChecked, setIsChecked] = useState(false);

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsChecked(e.target.checked);
	};

	useEffect(() => {
		const lis = document.querySelectorAll("ul li");
		if (isChecked) {
			lis.forEach((li, index) => {
				setTimeout(() => {
					li.classList.add("show");
				}, index * 100);
			});
		} else {
			lis.forEach((li, index) => {
				setTimeout(() => {
					li.classList.remove("show");
				}, index * 100);
			});
		}
	}, [isChecked]);

	return (
		<>
			<nav className={`global-nav ${isChecked ? "open" : ""}`}>
				<div className="sp-menu-button">
					<input
						className="menu-icon__cheeckbox"
						type="checkbox"
						checked={isChecked}
						onChange={handleCheckboxChange}
					/>
					<div>
						<span></span>
						<span></span>
					</div>
				</div>
				<ul>
					<li>
						<Link to="/">HOME</Link>
					</li>
					<li>
						<Link to="/#story" onClick={() => setIsChecked(false)}>
							STORY
						</Link>
					</li>
					<li>
						<Link to="/#characters" onClick={() => setIsChecked(false)}>
							CHARACTERS
						</Link>
					</li>
					<li>
						<Link to="/product" onClick={() => setIsChecked(false)}>
							GOODS
						</Link>
					</li>
					<li>
						<Link to="/news" onClick={() => setIsChecked(false)}>
							NEWS
						</Link>
					</li>
					<li>
						<Link to="/fc-only" onClick={() => setIsChecked(false)}>
							FC ONLY
						</Link>
					</li>
				</ul>
			</nav>
		</>
	);
};

export default Menu;
