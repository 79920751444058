import React from "react";
import { useGlossary } from "../../contexts/GlossaryContext";

interface GlossaryTermProps {
	term: string;
	children: React.ReactNode;
}

const GlossaryTerm: React.FC<GlossaryTermProps> = ({ term, children }) => {
	const { openGlossary } = useGlossary();

	return (
		<span className="glossary-term" onClick={() => openGlossary(term)}>
			{children || term}
		</span>
	);
};

export default GlossaryTerm;
