import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { SITE_NAME } from "../constants/siteConfig";
import FCNewsList from "../components/fc-news/FCNewsList";
import Cookies from "js-cookie";

const FCNews: React.FC = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const cookieAikotoba = Cookies.get("aikotoba_correct");
		if (cookieAikotoba !== "true") {
			// Cookieが存在しない、もしくは正解していない場合は合言葉ページにリダイレクト
			navigate("/fc-only");
		}
	}, [navigate]);

	return (
		<div>
			<Helmet>
				<title>Tier 4 指定機外秘情報｜{SITE_NAME}</title>
			</Helmet>
			<div className="single-page inner">
				<header className="single-page-header">
					<div className="main-title-area">
						<span className="subtitle">FC ONLY</span>
						<div className="lines">
							<div className="line1"></div>
							<div className="line2"></div>
							<div className="line3"></div>
						</div>
						<h1 className="page-title">Tier 4 指定機外秘情報</h1>
					</div>
				</header>
				<FCNewsList />
			</div>
		</div>
	);
};

export default FCNews;
