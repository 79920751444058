import React, { useEffect, useState } from "react";
import FCNewsCard from "./FCNewsCard";

interface FCNews {
	id: string;
	title: string;
	createdAt: string;
	content: string;
}

const FCNewsList: React.FC = () => {
	const [news, setNews] = useState<FCNews[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const fetchNews = async () => {
			try {
				const response = await fetch(
					"https://parasitos-syndrome.microcms.io/api/v1/fc-only?limit=100",
					{
						headers: {
							"X-MICROCMS-API-KEY": "Xc8dv3BFTUG5obqxXnBA7wgiYWzH7VcoiANg",
						},
					}
				);
				const data = await response.json();
				setNews(data.contents);
				setLoading(false);
			} catch (error) {
				setError("Failed to fetch news");
				setLoading(false);
			}
		};

		fetchNews();
	}, []);

	if (loading) return <p>Loading news...</p>;
	if (error) return <p>{error}</p>;

	return (
		<div className="news-list inner">
			{news.map((article) => (
				<FCNewsCard key={article.id} article={article} />
			))}
		</div>
	);
};

export default FCNewsList;
