import React, { useRef, useEffect, useState } from "react";
import Youtube from "react-youtube";

const Credit: React.FC = () => {
	return (
		<section className="credit">
			<h2 className="heading center">CREDIT</h2>
			<div className="inner">
				<ul className="credits">
					<li>
						企画制作 :{" "}
						<a href="https://studiogoshu.com" target="_blank">
							株式会社Studio Goshu
						</a>
					</li>
					<li>原案 : すさのを／ヌビア</li>
					<li>原作 : ヌビア</li>
					<li>キャラクターデザイン : エターナル14歳／kuro太</li>
					<li>音楽 : 与猶啓至／ヌビア</li>
					<li>主題歌 : 榊原ゆい『Parasite Load』／レジ『依草付木』</li>
					<li>映像制作 : すさのを</li>
					<li>
						公式Webサイト :{" "}
						<a href="https://woz.co.jp" target="_blank">
							合同会社WOZ
						</a>
					</li>
				</ul>
			</div>
		</section>
	);
};

export default Credit;
