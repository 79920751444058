import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { SITE_NAME } from "../constants/siteConfig";
import NewsList from "../components/news/NewsList";

const News: React.FC = () => {
	return (
		<div>
			<Helmet>
				<title>特区武蔵 公式通信｜{SITE_NAME}</title>
			</Helmet>
			<div className="single-page inner">
				<header className="single-page-header">
					<div className="main-title-area">
						<span className="subtitle">NEWS</span>
						<div className="lines">
							<div className="line1"></div>
							<div className="line2"></div>
							<div className="line3"></div>
						</div>
						<h1 className="page-title">特区武蔵 公式通信</h1>
					</div>
				</header>
				<NewsList />
			</div>
		</div>
	);
};

export default News;
