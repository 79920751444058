import React from "react";
import { Link } from "react-router-dom";

interface NewsCardProps {
	article: {
		id: string;
		title: string;
		createdAt: string;
		content: string;
		thumbnail?: {
			url: string;
			height: number;
			width: number;
		};
		category?: string;
	};
}

const NewsCard: React.FC<NewsCardProps> = ({ article }) => {
	// Extracting the first 100 characters from the content for the excerpt
	const excerpt =
		article.content.replace(/<[^>]+>/g, "").substring(0, 100) + "...";

	// Formatting the date to MM/DD
	const date = new Date(article.createdAt);
	const month = date.toLocaleDateString("en-US", { month: "2-digit" });
	const day = date.toLocaleDateString("en-US", { day: "2-digit" });

	return (
		<Link to={`/news/${article.id}`} className="news-link">
			<div className="news-card">
				<p className="news-date">
					<span className="month">{month}</span>
					<span className="day">{day}</span>
				</p>
				{article.thumbnail && (
					<div className="news-thumbnail">
						<img
							className=""
							src={article.thumbnail.url}
							alt={article.title}
							width={article.thumbnail.width}
							height={article.thumbnail.height}
						/>
					</div>
				)}
				<div className="news-meta">
					<h2 className="news-title">{article.title}</h2>
					<div className="news-excerpt">{excerpt}</div>
					{article.category && (
						<p className="news-category">Category: {article.category}</p>
					)}
				</div>
			</div>
		</Link>
	);
};

export default NewsCard;
