import React, { useEffect, useState } from "react";
import glossaries from "../../data/glossaries";

interface GlossaryPopupProps {
	term: string;
	onClose: () => void;
}

const GlossaryPopup: React.FC<GlossaryPopupProps> = ({ term, onClose }) => {
	const [closing, setClosing] = useState(false);
	const glossaryItem = glossaries.find((item) => item.title === term);

	if (!glossaryItem) {
		return null;
	}

	const handleClose = () => {
		setClosing(true);
		setTimeout(onClose, 500); // Match the animation duration
	};

	return (
		<div className={`glossary-popup ${closing ? "closing" : ""}`}>
			<div className="content" onClick={handleClose}>
				<h2 className="label">用語解説</h2>
				<div className="glossary-header">
					<h3 className="title">{glossaryItem.title}</h3>
					{glossaryItem.image && (
						<div className="gloassary-image">
							<img src={glossaryItem.image} alt={glossaryItem.title} />
						</div>
					)}
				</div>

				<p>{glossaryItem.description}</p>
			</div>
			<div className="bg" onClick={handleClose}></div>
		</div>
	);
};

export default GlossaryPopup;
