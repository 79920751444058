import React from "react";
import { products } from "../../data/products";
import ProductCard from "./ProductCard";

const ProductsPage: React.FC = () => {
	return (
		<div className="products-grid">
			{products.map((product, index) => (
				<ProductCard key={index} product={product} />
			))}
		</div>
	);
};

export default ProductsPage;
