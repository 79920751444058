import React, { useRef, useEffect, useState } from "react";
import Youtube from "react-youtube";

const Movie: React.FC = () => {
	return (
		<section className="movie">
			<h2 className="heading center">
				M<span className="accent-color">O</span>VIE
			</h2>
			<div className="video-box">
				<div className="youtube-container">
					<Youtube videoId="YtpgJF5HPgM" />
				</div>
			</div>
		</section>
	);
};

export default Movie;
