import { useEffect, useRef } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useLocation,
} from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import "./styles/reset.css";
import "./styles/App.scss";
import Layout from "./components/layout/Layout";
import Home from "./pages/Home";
import News from "./pages/News";
import NewsDetail from "./pages/NewsDetail";
import FCNews from "./pages/FCNews";
import FCNewsDetail from "./pages/FCNewsDetail";
import Product from "./pages/Product";
import FcOnly from "./pages/FcOnly";
import NotFound from "./pages/NotFound";
import { GlossaryProvider, useGlossary } from "./contexts/GlossaryContext";
import GlossaryPopup from "./components/glossary/GlossaryPopup";
import { BGMProvider } from "./contexts/BGMContext";

const ScrollToAnchor: React.FC = () => {
	const { hash } = useLocation();

	useEffect(() => {
		if (hash) {
			const element = document.getElementById(hash.substring(1));
			if (element) {
				element.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [hash]);

	return null;
};

const MainAppContent: React.FC = () => {
	const { term, closeGlossary } = useGlossary();

	const ScrollToTop: React.FC = () => {
		const { pathname, search } = useLocation();
		const prevPathnameRef = useRef(pathname);
		const prevSearchRef = useRef(search);

		useEffect(() => {
			if (
				pathname !== prevPathnameRef.current ||
				search !== prevSearchRef.current
			) {
				window.scrollTo(0, 0);
				prevPathnameRef.current = pathname;
				prevSearchRef.current = search;
			}
		}, [pathname, search]);

		return null;
	};

	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>
			<ScrollToTop />
			<ScrollToAnchor /> {/* Add the ScrollToAnchor component here */}
			<Layout>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/news" element={<News />} />
					<Route path="/news/:id" element={<NewsDetail />} />
					<Route path="/fc-news" element={<FCNews />} />
					<Route path="/fc-news/:id" element={<FCNewsDetail />} />
					<Route path="/product" element={<Product />} />
					<Route path="/fc-only" element={<FcOnly />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</Layout>
			{term && <GlossaryPopup term={term} onClose={closeGlossary} />}
		</>
	);
};

const App: React.FC = () => (
	<GlossaryProvider>
		<BGMProvider>
			<HelmetProvider>
				<Router basename="/">
					<MainAppContent />
				</Router>
			</HelmetProvider>
		</BGMProvider>
	</GlossaryProvider>
);

export default App;
