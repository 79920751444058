import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface NewsArticle {
	id: string;
	title: string;
	content: string;
	createdAt: string;
	thumbnail?: {
		url: string;
	};
	category?: string;
}

const NewsDetail: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const [article, setArticle] = useState<NewsArticle | null>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const fetchArticle = async () => {
			try {
				const response = await fetch(
					`https://parasitos-syndrome.microcms.io/api/v1/news/${id}`,
					{
						headers: {
							"X-MICROCMS-API-KEY": "Xc8dv3BFTUG5obqxXnBA7wgiYWzH7VcoiANg",
						},
					}
				);
				const data = await response.json();
				setArticle(data);
				setLoading(false); // Loading is complete
			} catch (error) {
				setError("Failed to load article");
				setLoading(false); // Even on error, loading is complete
			}
		};

		fetchArticle();
	}, [id]);

	if (loading) {
		// Display a loading indicator while the data is being fetched
		return <p>Loading...</p>;
	}

	if (error) {
		// Display an error message if something goes wrong during fetching
		return <p>{error}</p>;
	}

	if (!article) {
		// Handle the case where the article might not exist
		return <p>Article not found</p>;
	}

	// Display the article only after it's fully loaded
	return (
		<div className="news-detail inner">
			<header className="news-header">
				<h1>{article.title}</h1>
				<p>{new Date(article.createdAt).toLocaleDateString()}</p>
				{article.thumbnail && (
					<img
						className="news-thumbnail"
						src={article.thumbnail.url}
						alt={article.title}
						style={{ width: "100%", height: "auto" }}
					/>
				)}
			</header>
			<div
				className="news-content"
				dangerouslySetInnerHTML={{ __html: article.content }}
			/>
			{article.category && <p>Category: {article.category}</p>}
		</div>
	);
};

export default NewsDetail;
