import React from "react";
import GlossaryTerm from "../components/glossary/GlossaryTerm";

export const Parser = (text: string) => {
	const rubyRegex = /\[Ruby rt="([^"]*)"\](.*?)\[\/Ruby\]/g;
	const glossaryRegex =
		/\[GlossaryTerm(?: title="([^"]*)")?\](.*?)\[\/GlossaryTerm\]/g;

	const processRubyTags = (text: string): (string | JSX.Element)[] => {
		const rubyParts: (string | JSX.Element)[] = [];
		let lastIndex = 0;
		let match;

		while ((match = rubyRegex.exec(text)) !== null) {
			rubyParts.push(text.slice(lastIndex, match.index)); // 前の部分のテキストを追加
			const reading = match[1]; // rt 属性の中身
			const word = match[2]; // タグ内の単語

			rubyParts.push(
				<ruby key={`ruby-${match.index}`}>
					{word}
					<rt>{reading}</rt>
				</ruby>
			);
			lastIndex = rubyRegex.lastIndex; // インデックスを更新
		}

		rubyParts.push(text.slice(lastIndex)); // 残りのテキストを追加
		return rubyParts;
	};

	const parts: (string | JSX.Element)[] = [];
	let lastIndex = 0;
	let match;

	// GlossaryTermの処理
	while ((match = glossaryRegex.exec(text)) !== null) {
		parts.push(text.slice(lastIndex, match.index)); // 前の部分のテキストを追加
		const title = match[1]; // title属性
		const content = match[2]; // タグ内のコンテンツ

		// GlossaryTermの中にRubyタグが含まれている可能性があるので処理
		const processedContent = processRubyTags(content);

		parts.push(
			<GlossaryTerm
				term={title || content.trim()}
				key={`glossary-${match.index}`}
			>
				<React.Fragment>{processedContent}</React.Fragment>
			</GlossaryTerm>
		);
		lastIndex = glossaryRegex.lastIndex; // インデックスを更新
	}

	parts.push(
		<React.Fragment>{processRubyTags(text.slice(lastIndex))}</React.Fragment>
	); // 残りのテキストをRubyタグ処理後に追加

	return parts;
};
