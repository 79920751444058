import React from "react";

const Footer: React.FC = () => {
	return (
		<footer className="footer">
			<div className="organizer-logo">
				<a href="https://x.com/StudioGoshu" target="_blank">
					<svg
						id="Layer_1"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 504.8 504.8"
					>
						<path d="M0,0v504.8h504.8V0H0ZM472.1,472.1H32.6V32.6h439.5v439.5ZM320.1,60.7l-47.6,58.9h-24.5L63.3,304.3V120.5l.6-.6,59.2-59.2h75l-75.8,75.8v33.9l109.7-109.7h88.1ZM441.4,94.7v162.4h-58.9v-35.3L111.7,439.2l-48.3-.4v-48.9L332.2,60.7h77.7l-237.4,269.1L441.4,94.7ZM441.4,284.4v154.3l-310.7.2,73.2-59.4h178.5v-36.2h-106l73-58.9h92Z" />
					</svg>
				</a>
			</div>
			<div className="social-links">
				<a className="icon" href="https://x.com/StudioGoshu" target="_blank">
					<svg
						id="fi_11823292"
						enable-background="new 0 0 1226.37 1226.37"
						viewBox="0 0 1226.37 1226.37"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="m727.348 519.284 446.727-519.284h-105.86l-387.893 450.887-309.809-450.887h-357.328l468.492 681.821-468.492 544.549h105.866l409.625-476.152 327.181 476.152h357.328l-485.863-707.086zm-144.998 168.544-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721h-162.604l-323.311-462.446z"></path>
						<g></g>
						<g></g>
						<g></g>
						<g></g>
						<g></g>
						<g></g>
						<g></g>
						<g></g>
						<g></g>
						<g></g>
						<g></g>
						<g></g>
						<g></g>
						<g></g>
						<g></g>
					</svg>
				</a>
				<a
					className="icon"
					href="https://www.youtube.com/@StudioGoshu"
					target="_blank"
				>
					<svg
						viewBox="-21 -117 682.66672 682"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="m626.8125 64.035156c-7.375-27.417968-28.992188-49.03125-56.40625-56.414062-50.082031-13.703125-250.414062-13.703125-250.414062-13.703125s-200.324219 0-250.40625 13.183593c-26.886719 7.375-49.03125 29.519532-56.40625 56.933594-13.179688 50.078125-13.179688 153.933594-13.179688 153.933594s0 104.378906 13.179688 153.933594c7.382812 27.414062 28.992187 49.027344 56.410156 56.410156 50.605468 13.707031 250.410156 13.707031 250.410156 13.707031s200.324219 0 250.40625-13.183593c27.417969-7.378907 49.03125-28.992188 56.414062-56.40625 13.175782-50.082032 13.175782-153.933594 13.175782-153.933594s.527344-104.382813-13.183594-154.460938zm-370.601562 249.878906v-191.890624l166.585937 95.945312zm0 0" />
					</svg>
				</a>
				<a
					className="icon"
					href="https://www.tiktok.com/@nubia_vcp"
					target="_blank"
				>
					<svg
						enable-background="new 0 0 512 512"
						height="512"
						viewBox="0 0 512 512"
						width="512"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g>
							<path d="m480.32 128.39c-29.22 0-56.18-9.68-77.83-26.01-24.83-18.72-42.67-46.18-48.97-77.83-1.56-7.82-2.4-15.89-2.48-24.16h-83.47v228.08l-.1 124.93c0 33.4-21.75 61.72-51.9 71.68-8.75 2.89-18.2 4.26-28.04 3.72-12.56-.69-24.33-4.48-34.56-10.6-21.77-13.02-36.53-36.64-36.93-63.66-.63-42.23 33.51-76.66 75.71-76.66 8.33 0 16.33 1.36 23.82 3.83v-62.34-22.41c-7.9-1.17-15.94-1.78-24.07-1.78-46.19 0-89.39 19.2-120.27 53.79-23.34 26.14-37.34 59.49-39.5 94.46-2.83 45.94 13.98 89.61 46.58 121.83 4.79 4.73 9.82 9.12 15.08 13.17 27.95 21.51 62.12 33.17 98.11 33.17 8.13 0 16.17-.6 24.07-1.77 33.62-4.98 64.64-20.37 89.12-44.57 30.08-29.73 46.7-69.2 46.88-111.21l-.43-186.56c14.35 11.07 30.04 20.23 46.88 27.34 26.19 11.05 53.96 16.65 82.54 16.64v-60.61-22.49c.02.02-.22.02-.24.02z" />
						</g>
					</svg>
				</a>
			</div>
			<div className="credits">
				<div className="row">
					<span>Copyright ©️Studio Goshu Co., Ltd. All Rights Reserved.</span>
					<span>
						Sponsored by{" "}
						<a href="https://woz.co.jp" target="_blank">
							合同会社WOZ
						</a>
					</span>
				</div>
				<div className="row">
					<span>
						<a href="">プライバシーポリシー</a>
					</span>
					<span>
						<a href="https://studiogoshu.com/about/" target="_blank">
							会社情報
						</a>
					</span>
					<span>
						<a href="https://studiogoshu.com/contact/" target="_blank">
							お問い合わせ
						</a>
					</span>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
