import React, { useState, useEffect } from "react";
import { useBGM } from "../../contexts/BGMContext";

const LoadingScreen: React.FC<{ onLoadingComplete: () => void }> = ({
	onLoadingComplete,
}) => {
	const [loading, setLoading] = useState(true);
	const { bgm, toggleBgm } = useBGM(); // BGMContextから取得

	useEffect(() => {
		// ローディングのシミュレーション（Go to Homeボタンが表示されるまでの待機）
		const timer = setTimeout(() => {
			setLoading(false);
		}, 2000); // 2秒間のローディングシミュレーション

		return () => clearTimeout(timer);
	}, []);

	const handleContinue = () => {
		onLoadingComplete(); // ホーム画面に進む処理
	};

	return loading ? (
		<div
			className="loading"
			style={{
				position: "fixed",
				width: "100%",
				height: "100%",
				background: "#000",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
			}}
		>
			{/* ローディング中の視覚効果 */}
			<div
				className="loading-effect"
				style={{ color: "#FF0000", fontSize: "24px", marginBottom: "20px" }}
			>
				Parasitic spreading
			</div>
		</div>
	) : (
		<div className="entrance">
			<div className="inner">
				<div className="row">
					<span>BGM : </span>
					<button
						className={`bgm-button ${bgm ? "on" : "off"}`}
						onClick={toggleBgm}
					>
						{bgm ? "ON" : "OFF"}
					</button>
				</div>
				<div className="row">
					<button className="go-to-home" onClick={handleContinue}>
						Go to Home
					</button>
				</div>
			</div>
		</div>
	);
};

export default LoadingScreen;
