export interface Character {
  name: string;
  furigana: string;
  images: string[];
  smallImage: string;
  themeColor: string;
  age: string;
  pathogenType: string;
  pathogenFurigana: string;
  synesthesia: string;
  organization: string;
  surveySummary: string;
}

const characters: Character[] = [
  {
    name: "空臥 明止",
    furigana: "Akito Kuuga",
    images: ["akito_std.png", "akito_eff.png"],
    smallImage: "akito_std.png",
    themeColor: "#ff0000",
    age: "30",
    pathogenType: "真位原型",
    pathogenFurigana: "タイプ・ホロ",
    synesthesia: "天蚕糸",
    organization: '[GlossaryTerm title="過たず招かれた災筐"][Ruby rt="パンドーラ"]過たず招かれた災筐[/Ruby][/GlossaryTerm]の首魁にして、衛生管理保全機構より特S級重犯罪者として指名手配されているテロリスト。',
    surveySummary: '[GlossaryTerm title="保菌者"]保菌者[/GlossaryTerm]としては身体から皮膚繊維を硬質化させた強度の高い糸を繰り出す能力を持ち、その戦闘力は[GlossaryTerm title="アスクレピオスの杖"]アスクレピオスの杖[/GlossaryTerm]の面々を以てしても「常軌を逸している」と評価される。そのため一部の保全機構職員たちからは「服わぬ狂星（ルビ：まつろ（わぬ）クロノス）」というコードネームで呼ばれ、常人とは隔絶した風格を持つ。'
  },
  {
    name: "十時 玲止",
    furigana: "Reito Tohji",
    images: ["reito_std.png", "reito_eff.png"],
    smallImage: "reito_std.png",
    themeColor: "#0092ca",
    age: "28",
    pathogenType: "新位元型",
    pathogenFurigana: "タイプ・ネオ",
    synesthesia: "厳霊",
    organization: '衛生管理保全機構 特務執行第六課所属「[GlossaryTerm title="アスクレピオスの杖"]アスクレピオスの杖[/GlossaryTerm]」の牽引を務める執行士長。',
    surveySummary: '保菌者としては雷を操り、パラシトスとの共生において大変優秀かつ勤勉で聡明。キャリアーとしての側面だけで言えば最高戦力とされる同課執行士の中でトップの能力を誇り、研究段階の新物質・[GlossaryTerm title="フトゥルミウム"]フトゥルミウム[/GlossaryTerm]製の試作武器を所有することを許された数少ない戦闘要員である。[GlossaryTerm title="星屑の落涙"][Ruby rt="スターダスト・ティアドロップ"]星屑の落涙[/Ruby][/GlossaryTerm]から1年後、[GlossaryTerm title="末期症状発露体"][Ruby rt="エゴ・ファージ"]末期症状発露体[/Ruby][/GlossaryTerm]への変成が始まろうとしていた幼馴染みの沓空聖佳を手に掛け、それによって明止からの大きな恨みを買うことになる。'
  },
  {
    name: "沓空 聖佳",
    furigana: "Seika Toukou",
    images: ["seika_std.png"],
    smallImage: "seika_std.png",
    themeColor: "#6d42c7",
    age: "20（死亡時）",
    pathogenType: "―――",
    pathogenFurigana: "―――",
    synesthesia: "―――",
    organization: "―――",
    surveySummary: '空臥明止と十時玲止の友人であり、19歳の時発生した「[GlossaryTerm title="星屑の落涙"][Ruby rt="スターダスト・ティアドロップ"]星屑の落涙[/Ruby][/GlossaryTerm]」の１年後に[GlossaryTerm title="末期症状発露体"][Ruby rt="エゴ・ファージ"]末期症状発露体[/Ruby][/GlossaryTerm]への変成を危惧した十時玲止の手によって死亡。周囲からは社交的と言われることもあるが実際はその逆で、他人を気にしすぎるあまり自分の意見を言えなかったり、人の厚意を断れず情に流されやすかったりするだけである。'
  },
  {
    name: "和歌月 宗円",
    furigana: "Souen Wakatsuki",
    images: ["souen_std.png"],
    smallImage: "souen_std.png",
    themeColor: "#f78536",
    age: "44",
    pathogenType: "分位類型",
    pathogenFurigana: "タイプ・レクト",
    synesthesia: "気焔",
    organization: '衛生管理保全機構 特務執行第六課「[GlossaryTerm title="アスクレピオスの杖"]アスクレピオスの杖[/GlossaryTerm]」所属、副執行士長。',
    surveySummary: "元陸上自衛隊特殊作戦群所属。上からも下からも慕われる人望の厚い人物だが、プライベートには謎も多い。保菌者としては皮膚繊維から体外へ可燃性（爆発性）のガスを排出する。またパラシトス寄生による能力だけでなく、あらゆる身体操作術に精通。"
  },
  {
    name: "犬伏 開",
    furigana: "Kai Inubuse",
    images: ["kai_std.png"],
    smallImage: "kai_std.png",
    themeColor: "#eeeeee",
    age: "32",
    pathogenType: "―――",
    pathogenFurigana: "―――",
    synesthesia: "―――",
    organization: '衛生管理保全機構 特務執行第六課「[GlossaryTerm title="アスクレピオスの杖"]アスクレピオスの杖[/GlossaryTerm]」所属。',
    surveySummary: '作戦立案や[GlossaryTerm title="新都大衙門"]新都大衙門[/GlossaryTerm]への出席を担当する執行士であり、本人も保菌者ではないため最前線での戦闘はほぼない。頭の回転が速くどんな場にもすぐ溶け込み、隅まで気が利くしっかり者だが、肝心なところでドジることも多いようだ。肝心の戦闘能力は……本人曰く「まだ僕は本気出してないだけ」。'
  },
  {
    name: "上夷 摩弥",
    furigana: "Maya Kamiebisu",
    images: ["maya_std.png"],
    smallImage: "maya_std.png",
    themeColor: "#ff5d9e",
    age: "20",
    pathogenType: "分位類型",
    pathogenFurigana: "タイプ・レクト",
    synesthesia: "繋架",
    organization: "過たず招かれた災筐 部隊β隊長。",
    surveySummary: "[GlossaryTerm]第一中央特区 武蔵[/GlossaryTerm]への居住が叶わず、貧困層として都市部より排斥され居場所を失う。衛生管理保全機構の研究対象となることを避けるため普段は保菌者であることを隠して生活しており、自身の体液が付着した物体同士を引き寄せ合う能力を用いる。"
  },
];

export default characters;
