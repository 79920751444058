import React, { useRef, useEffect } from "react";

const Hero: React.FC = () => {
	const videoRef = useRef<HTMLVideoElement | null>(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						if (videoRef.current) {
							videoRef.current.play().catch((error) => {
								console.error("Error attempting to play video:", error);
							});
						}
					}
				});
			},
			{ threshold: 0.5 }
		);

		if (videoRef.current) {
			observer.observe(videoRef.current);
		}

		return () => {
			if (videoRef.current) {
				observer.unobserve(videoRef.current);
			}
		};
	}, []);

	return (
		<section className="hero">
			<h1 className="logo">
				<img src="img/logo-full.png" alt="Logo" />
			</h1>
			<div className="video-box">
				<video
					ref={videoRef}
					width="640"
					height="360"
					controls={false}
					muted
					loop
					preload="metadata"
					playsInline
					autoPlay
				>
					<source src="video/hero-video.mp4" type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			</div>
		</section>
	);
};

export default Hero;
