import React, { useRef, useEffect, useState } from "react";
import CharacterSlider from "../../components/characters/CharacterSlider";

const Characters: React.FC = () => {
	return (
		<section className="characters">
			<h2 className="heading right" id="characters">
				<span className="subtitle">人物紹介</span>
				<span className="title">
					CHARACT<span className="accent-color">E</span>RS
				</span>
			</h2>
			<CharacterSlider />
			<span className="outline-typo left">人物紹介</span>
			<span className="outline-typo right">人物紹介</span>
		</section>
	);
};

export default Characters;
