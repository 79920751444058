import React from "react";
import { Product } from "../../data/products";

interface ProductCardProps {
	product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
	return (
		<div className="product-card">
			<div
				className={`product-image ${
					product.stocks === 0 ? "out-of-stock" : ""
				}`}
			>
				<img
					src={product.image || "/img/product/no-image.png"}
					alt={product.title}
				/>
			</div>
			<div className="product-desc">
				<h2 className="product-title">{product.title}</h2>
				<p className="product-price">¥{product.price.toLocaleString()}</p>

				<div className="product-buttons">
					<a
						href={product.url}
						className="product-button detail"
						target="_blank"
					>
						詳細を見る
					</a>
				</div>
			</div>
		</div>
	);
};

export default ProductCard;
