import React, {
	createContext,
	useContext,
	useState,
	ReactNode,
	useRef,
} from "react";

interface GlossaryContextProps {
	term: string | null;
	openGlossary: (term: string) => void;
	closeGlossary: () => void;
}

const GlossaryContext = createContext<GlossaryContextProps | undefined>(
	undefined
);

interface GlossaryProviderProps {
	children: ReactNode;
}

export const GlossaryProvider: React.FC<GlossaryProviderProps> = ({
	children,
}) => {
	const [term, setTerm] = useState<string | null>(null);
	const scrollPosition = useRef(0); // スクロール位置を保存

	const openGlossary = (term: string) => {
		scrollPosition.current = window.scrollY; // 現在のスクロール位置を保存
		setTerm(term);
	};

	const closeGlossary = () => {
		setTerm(null);
		window.scrollTo(0, scrollPosition.current); // スクロール位置を戻す
	};

	return (
		<GlossaryContext.Provider value={{ term, openGlossary, closeGlossary }}>
			{children}
		</GlossaryContext.Provider>
	);
};

export const useGlossary = () => {
	const context = useContext(GlossaryContext);
	if (!context) {
		throw new Error("useGlossary must be used within a GlossaryProvider");
	}
	return context;
};
